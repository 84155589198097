import { Typography } from "@mui/material";
import { determineInitials } from "./utils/InitialUtils";
import { useAuth } from "oidc-react";
import { useIntl } from "react-intl";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";

export default function UserProfile() {
  const { formatMessage: f } = useIntl();
  const auth = useAuth();

  const handleLogin = () => {
    auth !== null && auth.signIn();
  };

  function username(): string {
    return auth && auth.userData && auth.userData.profile && auth.userData.profile.name ? auth.userData.profile.name : "";
  }

  return (
    <div>
      <Avatar
        alt={username()}
        sx={{
          width: 40,
          height: 40,
          fontSize: 15,
          marginLeft: "auto",
          marginRight: "auto",
        }}>
        {username() != "" ? determineInitials(username()) : <PersonIcon />}
      </Avatar>
      <div>
        {username() != "" ? (
          <Typography variant="body1">{username()}</Typography>
        ) : (
          <a href="#" onClick={handleLogin}>
            <Typography variant="body1">{f({ id: "pleaselogin.message" })}</Typography>
          </a>
        )}
      </div>
    </div>
  );
}
