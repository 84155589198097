import { AuthContextProps } from "oidc-react/build/src/AuthContextInterface";
import { IAppConfig, IMeta, IPassword } from "./restModel";

import { determineAPIUrl } from "./utils/environmentUtils";
import axios, { AxiosInstance } from "axios";

const axiosinstance = axios.create({
  timeout: 25000,
  baseURL: determineAPIUrl(window.location),
});

function defineHeaderAxios(auth: AuthContextProps | null, axiosInstance: AxiosInstance) {
  if (auth === null) {
    console.warn("The authentication context is null!");
  } else {
    if (auth.userData) {
      const acessToken = auth.userData?.access_token;

      axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + acessToken;
    } else {
      console.warn("The authentication context is set but userdata is null!");
    }

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        const originalRequest = error.config;

        if (error != null && error.response != null && error.response.status === 401 && !originalRequest._retry) {
          console.debug("Performing re-authentication to get token ...");
          originalRequest._retry = true;

          auth.signIn();

          if (auth.userData) {
            console.debug("The re-authentication was successfull.");
            const acessToken = auth.userData?.access_token;

            console.debug("Performing second attempt ...");
            axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + acessToken;
            return axiosInstance(originalRequest);
          }
        }
        console.warn("Error while handling request: unable to get token for second request!");
        return Promise.reject(error);
      }
    );
  }
}

export async function getMeta(auth: AuthContextProps | null): Promise<IMeta | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const userId = auth && auth.userData ? auth.userData.profile.preferred_username : "*";
    const response = await axiosinstance.get("/user/" + userId + "/password/meta");
    return response.data;
  } catch (error: any) {
    console.error("An error occured while trying to request the meta data:" + JSON.stringify(error));
    throw error;
  }
}

export async function getAppConfig(): Promise<IAppConfig | null> {
  try {
    const response = await axiosinstance.get("/config");
    return response.data;
  } catch (error: any) {
    console.error("Error while trying to query application config.", JSON.stringify(error));
    throw error;
  }
}

export async function getPassword(auth: AuthContextProps | null): Promise<IPassword | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const userId = auth && auth.userData ? auth.userData.profile.preferred_username : "*";
    const response = await axiosinstance.get("/user/" + userId + "/password");
    return response.data;
  } catch (error: any) {
    console.error("An error occured while trying to request a password:" + JSON.stringify(error));
    throw error;
  }
}
