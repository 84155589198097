import { Dialog } from "@mui/material";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import useAPIError from "./useAPIError";

const ErrorDialog = () => {
  const theme = useTheme();
  const error = useAPIError();

  const handleOk = () => {
    error.removeError();
  };

  return (
    <div>
      <Dialog open={error.showDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{(error.type = "error" ? "Error" : "Warning")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{error.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleOk}
            sx={{
              borderColor: theme.palette.primary.main,
              margin: theme.spacing(0),
              color: theme.palette.grey[500],
              padding: theme.spacing(0),
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorDialog;
