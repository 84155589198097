import { APIErrorProvider } from "./Dialogs/error/APIErrorProvider";
import { AuthProvider, AuthProviderProps } from "oidc-react";
import { Box, Paper, ThemeProvider } from "@mui/material";
import { IAppConfig } from "./restModel";
import { IntlProvider } from "react-intl";
import { SnackbarProvider } from "notistack";
import { getAppConfig } from "./restFacade";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorDialog from "./Dialogs/error/ErrorDialog";
import PasswordFieldAndMessage from "./PasswordFieldAndMessage";
import React, { useEffect, useState } from "react";
import UserProfile from "./UserProfile";
import ZebLogoIcon from "./ZebLogoIcon";
import backgroundImage from "./assets/images/zeb-bg.png";
import messagesDE from "./i18n/de/messages.json";
import messagesEN from "./i18n/en/messages.json";
import zebTheme, { primaryColor } from "./theme/zebTheme";

// load localization files (client-side)
const messages: { [key: string]: any } = {
  de: messagesDE,
  en: messagesEN,
};

const currentLanguage = navigator.language.split(/[-_]/)[0];

export default function App(): React.ReactElement {
  const [authProviderConfig, setAuthProviderConfig] = useState<AuthProviderProps | null>(null);

  useEffect(() => {
    getAppConfig()
      .then((result) => {
        const config = buildAuthProviderConfig(result);
        setAuthProviderConfig(config);
      })
      .catch((err) => {
        console.debug("Error while trying to configure authentication provider.", JSON.stringify(err));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildAuthProviderConfig = (appConfig: IAppConfig | null): AuthProviderProps => {
    const authority = appConfig?.["idp-well-known-configuration"].includes(".well-known/openid-configuration")
      ? appConfig["idp-well-known-configuration"].split(".well-known/openid-configuration")[0]
      : "";

    const authProviderConfig: AuthProviderProps = {
      authority: appConfig ? authority : "",
      clientId: appConfig ? appConfig["client-id"] : "",
      redirectUri: window.location.href,
      autoSignIn: true,
    };
    return authProviderConfig;
  };

  return (
    <>
      {authProviderConfig ? (
        <AuthProvider {...authProviderConfig}>
          <ThemeProvider theme={zebTheme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
                <APIErrorProvider>
                  <Box
                    sx={{
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                    minHeight="100vh"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <>
                      <Paper elevation={2} sx={{ width: "450px", height: "450px" }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          p={2}
                          height="100%">
                          <Box display="flex" flexDirection="column" justifyContent="center" minHeight="15%">
                            <ZebLogoIcon color={primaryColor} size={4} />
                          </Box>
                          <Box display="flex" flexDirection="column" justifyContent="center" minHeight="25%">
                            <UserProfile />
                          </Box>
                          <Box display="flex" flexDirection="column" justifyContent="center" minHeight="50%" width="100%">
                            <PasswordFieldAndMessage />
                          </Box>
                        </Box>
                      </Paper>
                    </>
                  </Box>
                  <ErrorDialog />
                </APIErrorProvider>
              </IntlProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </AuthProvider>
      ) : null}
    </>
  );
}
